import {v4 as uuidv4} from 'uuid';

const fetchResponse = async (path, requestOptions) => {
  requestOptions = setAuthenticationInHeader(requestOptions)
  const res = await fetch(path, requestOptions)
  if(res.ok) return res
  if (res.status === 401) {
    login()
    throw new Error('Unauthorized')
  }
  throw res
}

const setAuthenticationInHeader = (requestOptions) => {
  const idToken = getIdToken()
  if(idToken) requestOptions =
    {...requestOptions,
      headers: { ...requestOptions.headers,
        Authorization: 'Bearer ' + idToken
      }
    }
  return requestOptions
}

const getIdToken = () => {
  if(document.cookie.includes('id_token=')) {
    return document.cookie
      .split('; ')
      .find(row => row.startsWith('id_token='))
      .split('=')[1]
  }
  return ''
}

const login = () => {
  let {state, nonce} = genState()
  window.location.assign(`/login?state=${state}&nonce=${nonce}`)
}

const genState = () => {
  let state = uuidv4()
  let nonce = uuidv4()
  let redirectUri = window.location.href
  localStorage.setItem("login.state", JSON.stringify({[state]: {redirectUri: redirectUri}, nonce}))
  return {state,nonce}
}

export {
  login,
  fetchResponse
}
