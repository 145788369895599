import FeedConfirmationView from "../FeedConfirmationView";
import OpenBankingFeedConfirmationView from "../OpenBankingFeedConfirmationView";
import * as _ from "lodash";
import React from "react";
import OpenBankingAccountSummaryContainer from "./components/OpenBankingAccountSummaryContainer";
import {FullStory} from "@fullstory/browser";

const FeedConfirmationResultContainer = ({source,
                                           areAllAccountsOnboarded,
                                           accountsNotYetOnboarded,
                                           redirectUrl,
                                           selectedOnboardAccounts,
                                           alreadyOnboardedAccounts
                                         }) => {
  const getOpenBankingSummarySection = () => {
    if (areAllAccountsOnboarded) {
      FullStory('trackEvent', {
        name: 'Onboard Finalise Confirmation',
        properties: {
          status: "alreadyOnboarded",
          source: source
        },
      })
    }
    const unselectedAccounts = accountsNotYetOnboarded.filter(account => !selectedOnboardAccounts.includes(account));
    if (_.isEmpty(unselectedAccounts)) {
      FullStory('trackEvent', {
        name: 'Onboard Finalise Confirmation',
        properties: {
          status: "allSuccess",
          source: source
        },
      })} else {
      FullStory('trackEvent', {
        name: 'Onboard Finalise Confirmation',
        properties: {
          status: "selectedSuccess",
          source: source
        },
      })
    }

    return <>
      {!_.isEmpty(selectedOnboardAccounts) && <OpenBankingAccountSummaryContainer accounts={selectedOnboardAccounts} resultStatus={_.isEmpty(unselectedAccounts) ? ((selectedOnboardAccounts.length > 1) ? "selected" : "allSuccess") : "selected"}/>}
      {!_.isEmpty(unselectedAccounts) && <OpenBankingAccountSummaryContainer accounts={unselectedAccounts} resultStatus={"unselected"}/>}
      {!_.isEmpty(alreadyOnboardedAccounts) && <OpenBankingAccountSummaryContainer accounts={alreadyOnboardedAccounts} resultStatus={"accountAlreadyOnboarded"}/>}
    </>
  }

  if (source?.toLowerCase() === "OpenBanking".toLowerCase()) {
    return (
      <OpenBankingFeedConfirmationView
        summarySection={getOpenBankingSummarySection()}
        redirectUrl={redirectUrl}
      />
    );
  }
  return (
    <FeedConfirmationView
      accountData={selectedOnboardAccounts}
      redirectUrl={redirectUrl}
    />
  );
}

export default FeedConfirmationResultContainer;
